@import '../../../styles/variables';
@import '../../../styles/mixin';

.search__header {
  top: 48px !important;

  @include media(lg) {
    top: 104% !important;
  }
}

.search__header_top_row {
  border-bottom: 1px solid $header-divider-top !important;
}

.search__header_center_row {
  border-bottom: $header-weight-divider solid $header-divider !important;
}
