@import '../../styles/variables';
@import '../../styles/mixin';

.navbar {
  height: 100%;

  .navbar_list {
    height: 100%;
    display: flex;
    width: max-content;
    flex-direction: row;
    user-select: none;

    .navbar_list__item {
      align-items: center;
      color: $nav-text-color;
      cursor: pointer;
      display: flex;
      column-gap: 4px;
      height: 100%;
      padding-inline: 5px;
      text-align: center;
      padding: 12px 10px 8px;

      @include media(xl) {
        padding-inline: 10px;
      }

      h4,  > a {
        font-family: var(--heading-font-family) !important;
        color: $nav-text-color !important;
        font-size: $text-base;
        line-height: 1rem;
        font-weight: 400;
      }

      i {
        color: $nav-icon-color;
        font-size: $text-xl;
        transition: all 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      .navbar_menu {
        display: none;
      }

      &:hover {
        i {
          transform: rotate(180deg);
        }

        border-bottom: 4px solid $tab-btn !important;

        .navbar_menu {
          display: flex;
        }
      }

      &:has([data-type='single']) {
        position: relative;
      }
    }
  }

  li {
    border-bottom: 4px solid transparent;

    &:hover {
      border-bottom: 4px solid $mega-link-hover;
    }
  }
}
