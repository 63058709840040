@import '../../styles/variables';
@import '../../styles/mixin';

.shopping__action {
  position: relative;

  svg {
    height: 24px !important;
    width: 24px !important;

    @media screen and (max-width: 400px) {
      width: 24px !important;
      height: 24px !important;
    }

    * {
      stroke: $header-action-icon-color;
    }
  }

  .counter {
    background: $badge-counter;
    border-radius: 50%;
    color: $badge-counter-text;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: $text-xs;
    height: 24px;
    width: 24px;
    line-height: 12px;
    right: -12px;
    top: -10px;
  }
}
