@import '../../styles/variables';
@import '../../styles/mixin';

.footer__flex_column {
  display: flex;
  flex-direction: column;
}

.footer__flex_row {
  display: flex;
  flex-direction: row;
}

.footer_font {
  line-height: 20px;
  font-size: $text-sm !important;
}

.align-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include media(sm) {
    align-items: flex-start;
  }
}

.footer {
  position: relative;
  z-index: 2;
  border-top: $border-top;
  padding-top: 48px;
  padding-bottom: 48px;
  font-size: $text-base;
  color: $color-text-footer;
  background: $background-footer;
  background-image: $footer-background-image;
  background-position: 50%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  font-weight: $footer-font-weight-text;
  overflow: hidden;

  .footer__borde_adorment {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-image: $footer-adorment-background-image;
    height: $footer-adorment-height;
  }

  .footer__container {
    @extend .footer__flex_column;
    row-gap: 40px;
    width: 100%;

    @include media(2xl) {
      row-gap: 48px;
    }
  }

  p,
  a,
  span {
    letter-spacing: -0.02px;
  }

  figure {
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
  }

  .footer__body {
    @extend .footer__flex_column;
    width: 100%;
    row-gap: 40px;

    @include media(xl) {
      flex-direction: row;
      column-gap: 16px;
    }

    @include media(2xl) {
      flex-direction: row;
      column-gap: 80px;
    }

    .footer__container_sections {
      @extend .footer__flex_column;
      column-gap: 16px;
      row-gap: 24px;

      @include media(sm) {
        flex-direction: row;
        flex-grow: 1;
        flex-wrap: wrap;
      }

      @include media(md) {
        column-gap: 80px;
      }

      @include media(xl) {
        column-gap: 40px;
      }

      @include media(2xl) {
        column-gap: 80px;
      }

      .logo {
        @extend .align-logo;
        position: relative;
        height: $footer-logo-max-height;
        width: 100%;
        max-width: $footer-logo-max-width;

        @include media(xl) {
          width: 90px;
        }

        figure {
          position: relative;

          img {
            max-height: $footer-logo-max-height;
            width: auto;

            @include media(xl) {
              height: auto;
              max-height: none;
              width: 90px;
            }
          }
        }
      }

      .footer__sections {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        row-gap: 32px;
        column-gap: 16px;

        @media screen and (max-width: 428px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include media(sm) {
          grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
          max-width: 888px;
        }

        @include media(xl) {
          max-width: 700px;
        }

        @include media(2xl) {
          max-width: 763px;
        }

        @include media(3xl) {
          max-width: 1031px;
          column-gap: 32px;
        }

        .footer__section {
          @extend .footer__flex_column;
          row-gap: 4px;
          max-width: 160px;

          @include media(md) {
            flex-grow: 1;
          }

          .section_link {
            @extend .footer_font;
            font-weight: $footer-font-weight-text;
            margin-bottom: 0;
          }

          .section_title {
            @extend .footer_font;
            font-weight: $footer-font-weight-heading;
            margin-bottom: 8px;
          }
        }
      }
    }

    .footer__subscribe {
      @extend .footer__flex_column;
      row-gap: 24px;

      @include media(xl) {
        width: 345px;
      }

      @include media(2xl) {
        width: 420px;
      }

      @include media(3xl) {
        width: 560px;
      }

      .footer__subscribe_header {
        .subscribe__header_title {
          @extend .footer_font;
        }

        p {
          @extend .footer_font;
          margin-top: 4px;
        }
      }

      .footer__form {
        position: relative;

        .container__subscribe {
          @extend .footer__flex_column;
          gap: $text-base;

          button {
            border-radius: 0;
            flex-grow: 0;
            font-size: 24px !important;
            padding: 14px 0 15px 16px;
            font-weight: 500;
            width: 48px;
            position: absolute;
            right: 0;
            bottom: 2px;

            svg {
              font-size: $text-xl;

              * {
                stroke: $text-btn-footer;
              }

              &:hover {
                * {
                  stroke: $text-btn-footer-hover;
                }
              }
            }
          }

          input {
            padding: 16px 40px 16px 0 !important;
            font-size: 15px;
          }

          input::placeholder {
            opacity: 0.8 !important;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: -0.09px;
            font-weight: 400;
          }
        }
      }

      .socials_container {
        @extend .footer__flex_row;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        .socials {
          @extend .footer__flex_row;
          align-items: center;
          column-gap: 32px;
          height: 24px;

          @media screen and (max-width: 428px) {
            column-gap: 16px;
          }
        }

        .socials__item {
          figure {
            width: 24px;
            height: 24px;
          }
        }

        .footer_image {
          figure {
            width: 72px;
            height: 72px;
          }
        }
      }
    }
  }

  .footer__info_legal {
    @extend .footer__flex_column;
    row-gap: 16px;
    width: 100%;

    p,
    li {
      font-size: $text-sm;
    }

    ul {
      @extend .footer__flex_row;
      flex-wrap: wrap;
      column-gap: 16px;
      row-gap: 8px;

      li {
        @extend .footer__flex_row;

        column-gap: 16px;
      }
    }
  }

  .footer__copyright_container {
    .copyright {
      align-items: flex-end;
      width: 100%;

      @include media(md) {
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.footer_empty {
  @extend .footer__flex_column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 4px;
  padding: 20px;
  font-size: $text-base;
  color: $color-text-footer;
  background: $background-footer;
  font-size: $text-sm;
  min-height: 120px;

  h3 {
    font-size: $text-base;
    font-weight: 700;
  }

  @include media(sm) {
    padding: 40px;

    br {
      display: none;
    }
  }
}

.footer_image {
  figure {
    position: relative;
    width: 75px;
    height: 75px;
  }
}

.subscribe_bottom_variation__footer {
  @include media(sm) {
    padding-top: 48px !important;
    padding-bottom: 54px !important;
  }

  .subscribe_bottom_variation {
    &__footer__body {
      @extend .footer__flex_column;
      width: 100%;
      row-gap: 20px;

      @include media(md) {
        row-gap: 32px;
      }

      .footer__subscribe {
        @extend .footer__flex_column;
        width: -webkit-fill-available;
        row-gap: 24px;
        order: 2;

        @include media(lg) {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          order: 5;
        }

        .footer__subscribe_header {
          .subscribe__header_title {
            font-size: 24px !important;
          }

          @include media(lg) {
            max-width: 450px;
          }

          @include media(xl) {
            max-width: 44%;
          }
        }

        .footer__form {
          position: relative;

          @include media(xl) {
            width: 38%;
          }

          @include media(2xl) {
            width: 31%;
          }

          .container__subscribe {
            @extend .footer__flex_column;
            width: 100%;
            gap: $text-base;

            @include media(lg) {
              flex-direction: row;
              height: 40px;
            }

            input {
              width: 100%;
              flex-grow: 1;
              height: 40px;
            }

            button {
              border-radius: 4px;
              height: 40px;
              flex-grow: 1;
              font-size: 14px !important;
              font-weight: 500;
              background-color: $btn-background-footer;
              overflow: visible;
              padding: 0;

              &:hover {
                background-color: $btn-background-footer-hover;
              }

              @include media(lg) {
                max-width: 240px;
              }

              svg {
                font-size: $text-xl;

                * {
                  stroke: $text-btn-footer;
                }

                &:hover {
                  * {
                    stroke: $text-btn-footer-hover;
                  }
                }
              }
            }
            button * {
              padding: 11px 16px;
              border-radius: 4px;
              height: 40px;
            }

            input {
              padding: 16px 40px 16px 0 !important;
              font-size: 15px;
            }

            input::placeholder {
              opacity: 0.8 !important;
              font-size: 15px;
              line-height: 24px;
              letter-spacing: -0.09px;
              font-weight: 400;
            }
          }
        }
      }
    }

    &__logo {
      @extend .align-logo;
      position: relative;
      width: 100%;
      width: $footer-logo-max-width;
      height: $footer-logo-max-height;

      figure {
        position: relative;

        img {
          max-height: $footer-logo-max-height;
        }
      }
    }

    &__footer__container_sections {
      @extend .footer__flex_column;
      width: 100%;
      flex-grow: 1;
      gap: 16px;

      @include media(sm) {
        flex-direction: row;
        flex-wrap: wrap;
      }

      @include media(md) {
        gap: 24px;
      }

      @include media(lg) {
        column-gap: 56px;
        row-gap: 32px;
      }

      @include media(3xl) {
        column-gap: 72px;
        row-gap: 42px;
      }

      .footer__sections {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        gap: 32px;
        order: 3;

        @media screen and (max-width: 428px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include media(sm) {
          grid-template-columns: repeat(auto-fill, minmax(auto, auto));
        }

        @include media(md) {
          column-gap: 24px;
        }

        @include media(lg) {
          max-width: 694px;
          grid-template-columns: repeat(4, minmax(auto, auto));
        }

        @include media(xl) {
          max-width: 920px;
          grid-template-columns: repeat(5, minmax(auto, auto));
          column-gap: 32px;
        }

        @include media(2xl) {
          max-width: 70%;
        }

        .footer__section {
          @extend .footer__flex_column;
          width: 100%;
          row-gap: 12px;

          @include media(md) {
            max-width: 160px;
          }

          @include media(lg) {
            max-width: 200px;
          }

          @include media(3xl) {
            min-width: 180px;
          }

          .section_link {
            @extend .footer_font;
            font-weight: $footer-font-weight-text;
            margin-bottom: 0;
          }

          .section_title {
            @extend .footer_font;
            cursor: text;
            font-weight: $footer-font-weight-heading;
          }
        }
      }
    }

    &__footer__copyright_container {
      .copyright {
        align-items: flex-start;
        flex-direction: column-reverse;
        gap: 48px;
        width: 100%;

        @include media(md) {
          flex-direction: row;
          align-items: center;
        }
      }
    }

    &__address_container {
      display: flex;
      flex-direction: column;
      grid-column: span 2;
      gap: 12px;

      @include media(md) {
        grid-column: span 3;
      }

      @include media(xl) {
        grid-column: span 1;
        max-width: 160px;
      }

      @include media(2xl) {
        grid-column: span 1;
        max-width: fit-content;
      }

      .section_title {
        line-height: 20px;
        font-size: 0.875rem !important;
        font-weight: $footer-font-weight-heading;
      }

      .__address_section {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 12px;

        a {
          @extend .footer_font;
          font-style: normal;
          font-weight: $footer-font-weight-text;
        }

        .__address_location {
          line-height: 24px;
        }
      }
    }

    &__socials_container {
      @extend .footer__flex_row;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;

      .socials {
        @extend .footer__flex_row;
        align-items: center;
        column-gap: 12px;
        height: 24px;

        @media screen and (max-width: 428px) {
          column-gap: 16px;
        }
      }

      .socials__item {
        figure {
          width: 24px;
          height: 24px;
        }
      }
    }

    &__info_legal {
      @extend .footer__flex_column;
      gap: 24px;
      flex-grow: 1;
      text-wrap: wrap;
      //word-break: break-all;
      position: relative;
      order: 1;

      @include media(lg) {
        max-width: 230px;
      }

      @include media(xl) {
        max-width: 260px;
      }

      @include media(2xl) {
        max-width: 26%;
      }

      .company_info {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: space-between;
      }

      .license_container {
        @include media(lg) {
          max-width: 264px;
        }

        ul {
          li {
            gap: 16px;
          }
        }
      }
    }
  }
}

.with_background_image_variation_font {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.with_background_image_variation {
  position: relative;

  @include media(sm) {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  &__footer__body {
    @extend .footer__flex_column;
    width: 100%;
    row-gap: 28px;

    .footer__subscribe {
      @extend .footer__flex_column;
      gap: 24px;
      width: 100%;

      @include media(md) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      @include media(xl) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }

      .footer__subscribe_header {
        .subscribe__header_title {
          font-size: 14px !important;
        }

        p {
          @extend .with_background_image_variation_font;
        }
      }

      .footer__form {
        position: relative;
        width: 100%;

        @include media(md) {
          width: 100%;
          max-width: 350px;
        }

        @include media(lg) {
          max-width: 525px;
        }

        @include media(xl) {
          max-width: none;
        }

        .container__subscribe {
          @extend .footer__flex_column;
          gap: $text-base;

          @include media(sm) {
            flex-direction: row;
            height: 40px;
          }

          @include media(md) {
            width: 100%;
            max-width: 600px;
          }

          @include media(xl) {
            max-width: 100%;
          }

          label {
            border-radius: 4px !important;
          }

          button {
            border-radius: 4px;
            height: 40px;
            flex-grow: 1;
            font-size: 14px !important;
            font-weight: 500;
            background-color: $btn-background-footer;
            overflow: visible;
            padding: 0;

            &:hover {
              background-color: $btn-background-footer-hover;
            }

            @include media(sm) {
              max-width: 133px;
            }

            svg {
              font-size: $text-xl;

              * {
                stroke: $text-btn-footer;
              }

              &:hover {
                * {
                  stroke: $text-btn-footer-hover;
                }
              }
            }
          }
          
          button * {
            padding: 11px 16px;
            border-radius: 4px;
            height: 40px;
          }

          input {
            padding: 16px 40px 16px 0 !important;
            font-size: 15px;
            width: 100%;
            flex-grow: 1;
            height: 40px;
          }

          input::placeholder {
            opacity: 0.8 !important;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: -0.09px;
            font-weight: 400;
            font-weight: $footer-font-weight-text;
          }
        }
      }
    }
  }

  &__logo {
    @extend .align-logo;
    position: relative;
    width: 100%;
    max-width: $footer-logo-max-width;
    height: $footer-logo-max-height;

    figure {
      position: relative;
      width: -webkit-fill-available;

      img {
        max-height: $footer-logo-max-height;
      }
    }
  }

  &__footer__container_sections {
    @extend .footer__flex_column;
    width: 100%;
    flex-grow: 1;
    gap: 28px;
    flex-wrap: wrap;

    @include media(sm) {
      flex-direction: row;
    }

    @include media(md) {
      gap: 32px;
    }

    &_check {
      flex-wrap: nowrap;
    }

    .subscribe_and_info_legal_container {
      display: flex;
      flex-direction: column;
      gap: 12px;

      @include media(md) {
        max-width: 100%;
      }

      @include media(xl) {
        max-width: 355px;
      }

      @include media(2xl) {
        max-width: 480px;
      }

      @include media(3xl) {
        max-width: 642px;
      }

      .footer__info_legal {
        @extend .footer__flex_column;
        row-gap: 8px;
        width: 100%;

        ul {
          @extend .footer__flex_row;
          flex-wrap: wrap;
          column-gap: 16px;
          row-gap: 8px;

          li {
            @extend .footer__flex_row;
            column-gap: 16px;
          }
        }
      }

      p,
      span {
        @extend .with_background_image_variation_font;
      }
    }

    .info_legal_container_check {
      max-width: 100%;
    }

    .footer__sections {
      flex-grow: 1;
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      gap: 32px;

      @media screen and (max-width: 428px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include media(lg) {
        column-gap: 24px;
        width: max-content;
      }

      @include media(xl) {
        grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
      }

      @include media(3xl) {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
        column-gap: 32px;
      }

      .footer__section {
        @extend .footer__flex_column;
        width: 100%;
        row-gap: 12px;
        max-width: 186px;

        @include media(md) {
          max-width: 160px;
        }

        @include media(lg) {
          max-width: none;
        }

        @include media(xl) {
          max-width: 115px;
        }

        @include media(3xl) {
          max-width: 130px;
        }

        .section_link {
          @extend .footer_font;
          font-weight: $footer-font-weight-text;
          margin-bottom: 0;
        }

        .section_title {
          @extend .footer_font;
          cursor: text;
          font-weight: $footer-font-weight-heading;
        }
      }
    }
  }
  &__address_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;

    @include media(lg) {
      width: 320px;
    }

    @include media(xl) {
      grid-column: span 1;
      width: 262px;
      flex-shrink: 0;
    }

    @include media(2xl) {
      grid-column: span 1;
      width: 320px;
      flex-shrink: 0;
    }

    .section_title {
      line-height: 20px;
      font-size: 0.875rem !important;
    }

    .__address_section {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 12px;

      .address_item {
        display: flex;
        gap: 4px;
      }

      a,
      span {
        @extend .with_background_image_variation_font;
      }

      .__address_location {
        line-height: 24px;
      }
    }
  }

  &__socials_container {
    @extend .footer__flex_row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .socials {
      @extend .footer__flex_row;
      align-items: center;
      column-gap: 16px;
      height: 24px;

      @media screen and (max-width: 428px) {
        column-gap: 32px;
      }
    }

    .socials__item {
      figure {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__footer__copyright_container {
    .copyright {
      align-items: center;
      flex-direction: column-reverse;
      gap: 12px;
      width: 100%;

      p {
        @extend .with_background_image_variation_font;
      }

      @include media(md) {
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.links_and_subscribe_center_variation {
  position: relative;

  @include media(sm) {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  &__footer__body {
    @extend .footer__flex_column;
    width: 100%;
    row-gap: 28px;

    @include media(lg) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      grid-template-areas:
        'item1 item1'
        'item2 item3'
        'item4 item4';

      row-gap: 32px;
      column-gap: 2rem;

      & > div:nth-child(1) {
        grid-area: item1;

        @media screen and (max-width: 1280px) {
          grid-column: 1 / -1;
          justify-self: center;
          align-self: center;
        }
      }

      & > div:nth-child(2) {
        grid-area: item2;
      }

      & > div:nth-child(3) {
        grid-area: item3;
      }

      & > div:nth-child(4) {
        grid-area: item4;
      }
    }

    @include media(xl) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-template-areas:
        '. item1 .'
        'item2 item3 item4';
      column-gap: 3rem;
    }

    @include media(2xl) {
      column-gap: 5rem;
    }

    .subscribe_and_logo_container {
      @extend .footer__flex_column;
      row-gap: 28px;
      align-items: start;
      width: 100%;

      @include media(lg) {
        row-gap: 32px;
        max-width: 556px;
        align-items: center;
      }

      .footer__subscribe {
        @extend .footer__flex_column;
        gap: 24px;
        width: 100%;

        @include media(lg) {
          max-width: 556px;
        }

        @include media(xl) {
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
        }

        .footer__subscribe_header {
          .subscribe__header_title {
            font-size: 14px !important;
          }

          p {
            @extend .with_background_image_variation_font;
          }
        }

        .footer__form {
          position: relative;
          width: 100%;

          @include media(lg) {
            max-width: 525px;
          }

          @include media(xl) {
            max-width: none;
          }

          .container__subscribe {
            @extend .footer__flex_column;
            gap: $text-base;
            width: 100%;

            @include media(sm) {
              flex-direction: row;
              height: 40px;
            }

            @include media(lg) {
              max-width: 600px;
              height: 48px;
            }

            @include media(xl) {
              max-width: 100%;
            }

            label {
              border-radius: $border-radius-input !important;
              height: 40px !important;
              
              @include media(lg){
                height: 48px !important;
              }
            }

            button {
              border-radius: $border-radius-btn;
              height: 40px;
              flex-grow: 1;
              font-size: 14px !important;
              font-weight: 500;
              background-color: $btn-background-footer;
              color: $text-btn-footer;
              overflow: visible;
              padding: 0;

              &:hover {
                background-color: $btn-background-footer-hover;
                color: $text-btn-footer-hover
              }

              @include media(sm) {
                max-width: 133px;
              }

              @include media(lg){
                height: 48px;
              }

              svg {
                font-size: $text-xl;

                * {
                  stroke: $text-btn-footer;
                }

                &:hover {
                  * {
                    stroke: $text-btn-footer-hover;
                  }
                }
              }
            }
            /* button * {
              padding: 11px 16px;
              border-radius: 4px;
              height: 40px;
            } */

            input {
              padding: 16px 40px 16px 0 !important;
              font-size: 15px;
              width: 100%;
              flex-grow: 1;
              height: 40px;
              border-radius: $border-radius-input !important;

              @include media(lg){
                height: 48px;
              }
            }

            input::placeholder {
              opacity: 0.8 !important;
              font-size: 15px;
              line-height: 24px;
              letter-spacing: -0.09px;
              font-weight: 400;
              font-weight: $footer-font-weight-text;
            }
          }
        }
      }
    }

    &__logo {
      @extend .align-logo;
      margin: 0 auto;
      position: relative;
      width: 100%;
      max-width: $footer-logo-max-width;
      height: $footer-logo-max-height;

      figure {
        position: relative;
        width: -webkit-fill-available;

        img {
          max-height: $footer-logo-max-height;
        }
      }
    }

    .footer__sections {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      gap: 32px;

      @media screen and (max-width: 428px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include media(lg) {
        column-gap: 24px;
        grid-auto-flow: column;
        width: 630px;
      }

      @include media(xl) {
        grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
        width: 557px;
      }

      @include media(3xl) {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
        column-gap: 32px;
      }

      .footer__section {
        @extend .footer__flex_column;
        width: 100%;
        row-gap: 12px;
        max-width: 186px;

        @include media(md) {
          max-width: 160px;
        }

        @include media(lg) {
          max-width: none;
        }

        @include media(xl) {
          max-width: 115px;
        }

        @include media(3xl) {
          max-width: 130px;
        }

        .section_link {
          @extend .footer_font;
          font-weight: $footer-font-weight-text;
          margin-bottom: 0;
        }

        .section_title {
          @extend .footer_font;
          cursor: text;
          font-weight: $footer-font-weight-heading;
        }
      }
    }

    &__address_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;

      @include media(lg) {
        width: 320px;
      }

      @include media(xl) {
        grid-column: span 1;
        width: 262px;
        flex-shrink: 0;
      }

      @include media(2xl) {
        grid-column: span 1;
        width: 320px;
        flex-shrink: 0;
      }

      .section_title {
        line-height: 20px;
        font-size: 0.875rem !important;
      }

      .__address_section {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 12px;

        .address_item {
          display: flex;
          gap: 4px;

          a {
            font-size: $text-xs;

            @include media(lg) {
              font-size: $text-sm;
            }
          }

          svg {
            width: 16px;
            height: 16px;
            margin-right: 4px;

            path {
              stroke: $color-text-footer;
            }
          }
        }

        a,
        span {
          @extend .with_background_image_variation_font;
        }

        .__address_location {
          line-height: 24px;
        }
      }

      .__socials_container {
        @extend .footer__flex_row;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;

        .socials {
          @extend .footer__flex_row;
          align-items: center;
          column-gap: 32px;
          height: 24px;

          @include media(lg) {
            column-gap: 16px;
          }
        }

        .socials__item {
          figure {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &__footer__copyright_container {
      .copyright {
        align-items: center;
        flex-direction: column-reverse;
        gap: 12px;
        width: 100%;

        p {
          @extend .with_background_image_variation_font;
        }

        @include media(md) {
          flex-direction: row;
          align-items: center;
        }
      }
    }

    &__info_legal {
      @extend .footer__flex_column;
      gap: 12px;
      flex-grow: 1;
      text-wrap: wrap;
      // word-break: break-all;
      position: relative;
      width: 100%;

      p {
        font-size: 12px;
      }

      @include media(xl) {
        max-width: 260px;
      }

      @include media(2xl) {
        max-width: 409px;
      }

      @include media(3xl) {
        max-width: 556px;
      }

      .logo_and_terms_container {
        display: flex;
        gap: 1rem;
        align-items: center;

        @media screen and (max-width: 420px) {
          flex-direction: column;
        }

        @include media(xl) {
          align-items: flex-start;
          flex-direction: column;
        }

        @include media(2xl) {
          align-items: center;
          flex-direction: row;
        }

        .footer_image {
          figure {
            position: relative;
            width: 56px;
            height: 56px;
          }
        }
      }

      .company_info {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: space-between;
      }

      .license_container {
        ul {
          display: flex;
          flex-wrap: wrap;
          row-gap: 0.5rem;
          column-gap: 16px;
          row-gap: 4px;

          @include media(xl) {
            column-gap: 24px;
          }

          li {
            font-size: $text-xs !important;
            position: relative;

            &:not(:last-child)::after {
              content: ' | ';
              position: absolute;
              display: block;
              opacity: 0.7;
              right: -8px;
              top: 0;
              color: $color-text-footer !important;

              @include media(xl) {
                right: -12px;
              }
            }

            @include media(xl) {
              font-size: $text-sm;
            }
          }
        }
      }
    }
  }
}
