@import '../../styles/variables';
@import '../../styles/mixin';

.hero {
  display: flex;
  align-items: center;
  background: $background-hero;

  .swipper__hero_carousel {
    span[class~='swiper-pagination-bullet'] {
      background-color: $swiper-bullet; /* Cambia el color del bullet */
    }

    span[class~='swiper-pagination-bullet-active'] {
      background-color: $swiper-bullet-active !important; /* Cambia el color del bullet activo */
    }
  }

  &__spacing_bottom {
    margin-bottom: $margin-section-mobile;

    @include media(lg) {
      margin-bottom: $margin-section;
    }
  }

  &__with_margin {
    @include media(md) {
      padding-bottom: 20px;
      padding-top: 20px;
    }

    @include media(lg) {
      padding-bottom: 26px;
      padding-top: 26px;
    }

    @include media(xl) {
      padding-bottom: 32px;
      padding-top: 32px;
    }

    @include media(2xl) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
  }

  &__background_image {
    background-image: $background-hero-image;
    background-position: 50%;
    background-repeat: no-repeat !important; 
    background-size: cover !important;
  }

  &__withous_margin {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  figure {
    position: relative;
    align-self: center;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    background-color: $gray-50;
    //border: 1px solid $gray-100;

    img {
      height: auto;
    }

    svg {
      position: absolute;
      height: 30px;
      width: 30px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .container__grid {
    @include media(lg) {
      position: relative;
      display: grid;
      grid-template-columns: minmax(0, 1fr) 32.1%;
      row-gap: 12.1px;
      column-gap: 12px;
    }

    @include media(xl) {
      grid-template-columns: minmax(0, 1fr) 31.48%;
      row-gap: 24.06px;
      column-gap: 24px;
    }

    @include media(2xl) {
      grid-template-columns: minmax(0, 1fr) 31.8%;
      row-gap: 24.06px;
      column-gap: 24px;
    }

    @include media(3xl) {
      grid-template-columns: minmax(0, 1fr) 31.68%;
      row-gap: 36.06px;
      column-gap: 36px;
    }

    &_full {
      @include media(lg) {
        grid-template-columns: minmax(0, 1fr) 32.18%;
      }

      @include media(xl) {
        grid-template-columns: minmax(0, 1fr) 32.48%;
      }

      @include media(2xl) {
        grid-template-columns: minmax(0, 1fr) 31.92%;
      }

      @include media(3xl) {
        grid-template-columns: minmax(0, 1fr) 32.2%;
      }

      .banner__secondary:nth-child(3) {
        order: 4;
      }
    }
  }

  .container__grid_tree_columns {
    @include media(lg) {
      position: relative;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 12px;
    }

    @include media(xl) {
      gap: 24px;
    }

    @include media(3xl) {
      gap: 36px;
    }
  }

  .container__clear {
    @media screen and (max-width: 767px) {
      padding: 0;
      margin: 0;
      max-width: 100%;
    }
  }

  .container__full_width {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    max-width: none !important;

    img {
      border-radius: 0 !important;
    }
  }

  .container__effect_img {
    figure {
      img,
      svg {
        scale: 1;
        transition: 0.8s all ease 0.4s;

        &:hover,
        &:focus-within {
          transition: 0.8s all ease 0s;
          scale: 1.03;
        }
      }
    }
  }

  .banner__main {
    @include media(md) {
      grid-column-start: 1;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }

  .banner__main_four_columns {
    @include media(md) {
      grid-row-start: 1;
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }

  .banner__figure {
    display: none;

    @include media(sm) {
      display: block;
      padding-top: 58.32%;
    }
  }

  .banner__figure_simple {
    position: relative;
    display: none;
    padding-top: 33.33%;
    height: 0;

    @include media(sm) {
      display: block;

      img {
        height: auto;
      }
    }
  }

  .banner__figure_mobile {
    position: relative;
    padding-top: 58%;

    img {
      height: auto;
    }

    @include media(sm) {
      display: none;
    }
  }

  .banner__secondary {
    position: relative;
    display: none;

    @include media(lg) {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .banner__secondary_v {
    padding-top: 58.32%;
  }

  .banner__secondary_h {
    padding-top: 58%;
  }
}
