@import '../../styles/variables';
@import '../../styles/mixin';

.content-limit {
  border: 1px solid $limit-border-color;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding: 8px 14.5px 8px 14px;
  width: 100%;
  max-width: 185px;

  @include media(sm) {
    justify-self: end;
  }

  .content {
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: $text-sm;
    column-gap: 5px;
    line-height: 18px;

    .current {
      text-align: center;
      font-weight: 500;
      color: $limit-current-value;
    }
    .description {
      align-items: center;
      color: $limit-value;
    }
    &-icon {
      position: relative;
      cursor: pointer;
    }
  }

  svg {
    position: relative;
    top: 2px;
    height: 27px !important;
    width: 27px !important;

    @media screen and (max-width: 400px) {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .counter {
    background: $badge-counter;
    border-radius: 50%;
    color: $white;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: $text-xs;
    height: 24px;
    width: 24px;
    line-height: 12px;
    right: -12px;
    top: -5.5px;
  }
}
